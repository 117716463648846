<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <div class="logo">
      <!-- <img src="assets/images/logo.webp" height="50px" width="50px" style="border-radius: 50%;" [nbTooltip]="'AGENT_BANING'"> -->
      <a class="logo" style="text-decoration: none;border-left: 0px;" href="#" (click)="navigateHome()" *ngIf="window.innerWidth > 800">AGENT_BANING</a>
    </div>
    
  </div>
</div>
<ng-template #template let-data>
  <nb-card class="popover-card custom-card">
    <nb-card-header>{{'notification.label.notice'|translate}}
      <a (click)="readCheck(data?.list)" class="detailLabel float-right" *ngIf="data?.list?.length > 0">{{'notification.label.checkAll'|translate}}</a>
    </nb-card-header>
    <nb-card-body>
      <nb-list *ngIf="data?.list && data?.list?.length > 0">
        <nb-list-item *ngFor="let message of data?.list">
          <span>{{message?.reason}} &#8239;<label class="detailLabel" (click)="detail(message)">{{'notification.label.view'|translate}}</label></span>
        </nb-list-item>
      </nb-list>
      <label *ngIf="data?.list?.length === 0">{{'notification.label.noMessage'|translate}}</label>
    </nb-card-body>
    <nb-card-footer class="justify-content-center d-flex">
      <a class="detailLabel" (click)="navigate()">{{'notification.label.viewAll'|translate}}</a>
    </nb-card-footer>
  </nb-card>
</ng-template>
<div class="header-container">

  <nb-actions size="small">
    <nb-action [badgeText]="page?.totalElements"
               badgeStatus="danger">
      <button [nbPopover]="template" ghost nbButton class="custom-button"
              [nbPopoverContext]="{ list: listData }"
              nbPopoverPlacement="bottom">
        <nb-icon icon="bell-outline"></nb-icon>
      </button>
    </nb-action>
    <nb-action style="border: unset; box-shadow: unset" class="user-action text-basic-color"
               *nbIsGranted="['view', 'user','logout']">
      <nb-user [nbContextMenu]="userMenu"
               (menuClick)="menuClick($event)"
               [onlyPicture]="userPictureOnly"
               [name]="user"
               [picture]="img">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
